// src/components/games/GamePage/GamePage.jsx
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { GAMES_CONFIG } from '../../../config/games';
import './GamePage.scss';

const GamePage = () => {
  const { gameId } = useParams();
  const gameConfig = GAMES_CONFIG[gameId];

  if (!gameConfig) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="game-page">
      <div className="game-page__container">
        <iframe 
          src={gameConfig.url}
          className="game-page__iframe"
          title={gameConfig.title}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default GamePage;